import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <div className="container content-border">
        <div className="content has-text-centered">
          <p><strong>404 - NOT FOUND</strong></p>
          <hr />
          <p>Unfortunately we couldn't find that page for you.</p>
          <Link className="button is-small is-primary" to='/'>
            ← Return Home
          </Link>
        </div>
      </div>
      </section>
  </Layout>
)

export default NotFoundPage
